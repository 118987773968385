import {
  Tiles,
  StyledUl,
  Name,
  Description,
  Links,
  Link,
  Badge,
} from "./styled";

const Tile = ({ project, isLight }) => {
  const handleLinkClick = (event) => {
    if (project.state === "disabled") {
      event.preventDefault(); // Prevent navigation
    }
  };

  return (
  <>
    <Tiles>
       {isLight ? (
        <Badge image={project.imagePath} />
      ) : (
        <Badge image={project.imageDarkPath} />
      )}
      <StyledUl>
      <Name>{project.name}</Name>
        <Description>{project.description}</Description>
        <Links>
        <Link 
              to={project.routePath} 
              onClick={handleLinkClick} 
              style={{
                pointerEvents: project.state === "disabled" ? "none" : "auto",
                opacity: project.state === "disabled" ? 0.5 : 1,
              }}
            >
              {project.buttonName}
            </Link>
        </Links>
      </StyledUl>
    </Tiles>
  </>
  );
};

export default Tile;
