import {
  StyledInstagramLogo,
  StyledLinkedInLogo,
  StyledFacebookLogo,
} from "../../styledComponents/StyledLogos/styled";
import {
  IntroBlock,
  Text,
  Mail,
  Phone,
  Article,
  SocialMedia,
  SocialLink,
  PhoneWrapper,
} from "./styled";
import { ContainerContact } from "../../styledComponents/Container/styled";
import { ContactUs } from "./contact";
import { useSelector, useDispatch } from "react-redux";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect } from "react";
import {
  fetchGithubData,
  selectLoadingState,
} from "../../features/DataSlice/dataSlice";
import LoadingPage from "../../features/TechnicalTabs/LoadingPage";
import ErrorPage from "../../features/TechnicalTabs/ErrorPage";

function ContactForm() {
  const dispatch = useDispatch();
  const ifLoading = useSelector(selectLoadingState);

  useEffect(() => {
    dispatch(fetchGithubData());
  }, [dispatch]);
  const count4 = useMotionValue(0);
  const rounded4 = useTransform(count4, Math.round);
  const count5 = useMotionValue(0);
  const rounded5 = useTransform(count5, Math.round);
  const count6 = useMotionValue(0);
  const rounded6 = useTransform(count6, Math.round);

  useEffect(() => {
    const animation4 = animate(count4, 665, { duration: 3 });
    const animation5 = animate(count5, 225, { duration: 2.5 });
    const animation6 = animate(count6, 15, {duration: 3});
    return () => {
      animation4.stop();
      animation5.stop();
      animation6.stop();
    };
  }, []);

  let returned = "";

  switch (ifLoading) {
    case "loading":
      returned = <LoadingPage />;
      break;
    case "success":
      returned = (
        <ContainerContact>
          <IntroBlock>
            <Text>skontaktujmy się !</Text>
            <Mail href="mailto:kontakt@dragonscape.pl">kontakt@dragonscape.pl</Mail>
            <PhoneWrapper>Web: <Phone href="tel:+48665225015"> <span style={{ display: 'inline-block', marginRight: '7px' }}>(+48)</span><motion.span style={{ display: 'inline-block', marginRight: '7px' }}>{rounded4}</motion.span>
                <motion.span style={{ display: 'inline-block', marginRight: '7px' }}>{rounded5}</motion.span>
                <motion.span>0</motion.span>
                <motion.span style={{ display: 'inline-block' }}>{rounded6}</motion.span></Phone></PhoneWrapper>
            <SocialMedia>
              <SocialLink href="https://www.facebook.com/dragonscapePL" target="_blank">
                <StyledFacebookLogo />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/dragonscape/" target="_blank">
                <StyledLinkedInLogo />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/dragonscapepl/" target="_blank">
                <StyledInstagramLogo />
              </SocialLink>
            </SocialMedia>
            <Article>
              Identyfikacja wizualna, projekty graficzne, logo, design, strona www -
              jaka jest Twoja potrzeba? Napisz do nas, ustalimy co jest dla Ciebie
              ważne!
            </Article>
            <ContactUs />
          </IntroBlock>
        </ContainerContact>
      );
      break;
    case "error":
      returned = <ErrorPage />;
      break;
    default:
      returned = <LoadingPage />;
  }

  return returned;
}

export default ContactForm;